<i18n>
{
  "de": {
    "exportBuildings": "JSON-Export Liegenschaften"
  }
}
</i18n>

<template>
  <button v-if="portfolio" class="button" @click="onExport">{{ $t('exportBuildings') }}</button>
</template>

<script>
import exportService from '@/services/export-service.js'

export default {
  props: {
    portfolio: {
      type: Object,
      required: true,
    },
  },

  methods: {
    onExport() {
      exportService.exportPortfolioBuildingsJson(this.portfolio, {})
    },
  },
}
</script>
